import http from '../util/http'
import ResourceApi from '@/util/rest.js'
import config from '@/config'

export const commonApi = {
  getUploadToken(key) {
    let url = 'admin/common/qiniu/upload/token'
    if (key) {
      url += '?key=' + key
    }
    return http.get(url)
  },
  login(phone, password) {
    return http.post('admin/common/login', {
      data: {
        phone,
        password
      }
    })
  },
  updatePWd(password, password2) {
    return http.post('admin/common/password', {
      data: {
        password2,
        password
      }
    })
  },
  logout() {
    return http.get('admin/common/logout')
  },

  register(phone, password) {
    return http.post('admin/common/register', {
      data: {
        phone,
        password
      }
    })
  },
  checkLogined() {
    return http.get('admin/login')
  }
}


class ProductResourceApi extends ResourceApi {

}

class TagResourceApi extends ResourceApi {
  allParents(params) {
    return http.get(this.path + '/parents', {
      params
    })
  }
}

class UserApi extends ResourceApi {
  depositVip(userId, months, desc) {
    return http.post(this.path + '/deposit_vip', {
      data: {
        userId,
        months,
        desc
      }
    })
  }

  executeVipExpire() {
    return http.post(this.path + '/execute_vip_expire')
  }
}


class OrderApi extends ResourceApi {
  tongji(params) {
    return http.get(this.path + '/tongji', {
      params
    })
  }

  cancel (_id) {
    return http.post(this.path + '/cancel', {
      data: {
        _id
      }
    })
  }
}

class UserInfoApi extends ResourceApi {
  userInfo(params) {
    return http.get(this.path + '/info', {params})
  }

  depositVip(userId, months, desc) {
    return http.post(this.path + '/deposit_vip', {
      data: {
        userId,
        months,
        desc
      }
    })
  }

  executeVipExpire(userId) {
    return http.post(this.path + '/execute_vip_expire', {
      data: {
        userId,
      }
    })
  }

  updateVip(userId, vipEndAt) {
    return http.post(this.path + '/update_vip', {
      data: {
        userId,
        vipEndAt
      }
    })
  }

  appleOpenPay(userId, vipEndAt) {
    return http.post(this.path + '/applet/open/pay', {
      data: {
        userId,
        vipEndAt
      }
    })
  }
}

export const tagApi = new TagResourceApi('admin/tags')
export const userApi = new UserApi('admin/users')
export const orderApi = new ResourceApi('admin/orders')

export const shortChannelApi = new OrderApi('admin/short_channels')
export const shortChannelRecordApi = new OrderApi('admin/short_channel_records')
export const shortApi = new OrderApi('admin/shorts')
export const shortEpisodeApi = new OrderApi('admin/episodes')


export const privilegeApi = new ResourceApi('admin/privileges')

export const settingApi = new ResourceApi('admin/settings')
export const productApi = new ResourceApi('admin/products')

export const userInfoApi = new UserInfoApi('admin/user')

export const attributionADSettingApi = new ResourceApi('admin/attribution/ad_settings')
export const attributionChannelSettingApi = new ResourceApi('admin/attribution/channel_settings')
export const attributionChannelApi = new ResourceApi('admin/attribution/channels')

class AttributionApi extends ResourceApi {
  iosDates() {
    return http.get(this.path + '/ios/dates')
  }

  channels(params = {}) {
    return http.get(this.path + '/channels', {
      params
    })
  }

  adSettings(params) {
    return http.get(this.path + '/adSettings', {
      params
    })
  }

  androidDates(params = {}) {
    return http.get(this.path + '/android/dates', {
      params
    })
  }

  androidOrders(adId, appChannels, date, type, appId) {
    return http.get(this.path + '/android/orders', {
      params: {
        adId,
        appId,
        appChannels: JSON.stringify(appChannels),
        date,
        type
      }
    })
  }

  androidDatesOld() {
    return http.get(this.path + '/android/dates/old')
  }

  updateIosCost(date, cost) {
    return http.post(this.path + '/ios/cost', {
      data: {
        date, cost
      }
    })
  }

  uploadRepair(userId, repairAmount) {
    return http.post(this.path + '/pay/repair', {
      data: {
        userId,
        repairAmount
      }
    })
  }

  androidReportData(params = {}) {
    return http.get(this.path + '/android/channel/report/list', {
      params
    })
  }
}

export const attributionApi = new AttributionApi('admin/attribution')
export const appApi = new ResourceApi('admin/apps')


export const orderStatisticsApi = new OrderApi('admin/order')

export const attributionUserReportsApi = new ResourceApi('admin/attribution/users/reports')








